import logo from "./TLDR.COFFEE.png";
import tldrtile from "./test36nameless.01.png";
import nftroast from "./27-combined.10.png";
import React, { useEffect } from "react";
import "./App.css";
import { useMoralis } from "react-moralis";
import { Button, Box, Heading } from "@chakra-ui/react";
import { Container, Center } from "@chakra-ui/react";

const LogoutButton = () => {
  const { logout, isAuthenticating } = useMoralis();

  return (
    <Button
      display={"block"}
      colorScheme="red"
      variant="solid"
      isLoading={isAuthenticating}
      onClick={() => logout()}
      disabled={isAuthenticating}>
      Logout
    </Button>
  );
};

// ---------- APP -------------
function App() {
  const {
    authenticate,
    isWeb3Enabled,
    isAuthenticated,
    user,
    enableWeb3,
    Moralis,
  } = useMoralis();

  async function authWalletConnect() {
    const user = authenticate({
      provider: "walletconnect",
      chainId: 137,
      // mobileLinks: [
      //   "metamask",
      //   "trust",
      //   "rainbow",
      //   "argent",
      //   "imtoken",
      //   "pillar",
      // ],
      signingMessage: "Welcome to TLDR.COFFEE!",
    });
    console.log(user);
  }

  useEffect(() => {
    if (!isWeb3Enabled && isAuthenticated) {
      //enableWeb3({ provider: "walletconnect", chainId: 137 });
      console.log("web3 activated");
    }
  }, [isWeb3Enabled, isAuthenticated, enableWeb3]);

  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "hidden") {
      window.localStorage.removeItem("WALLETCONNECT_DEEPLINK_CHOICE");
    }
  });

  // ----- Authenticate in Metamask---------
  if (!isAuthenticated && !user) {
    console.log(user);
    return (
      <Container maxW="container.lg">
	    <br />
        <Center>
          <img width={500} height={500} src={logo} alt="logo" />
        </Center>
        <br />
        <Center>
          <Heading as="h2" size="3xl" p={10}>
            Wallet authentication
          </Heading>
        </Center>
        <Center>
          <Button
            colorScheme="green"
            size="lg"
            onClick={() => authenticate({ signingMessage: "Enter TLDR.COFFEE" })}>
            Sign in using Metamask
          </Button>
        </Center>
        <br />
        <Center>
          <Button
            colorScheme="blue"
            size="lg"
            onClick={() => authWalletConnect()}>
            Sign in using Wallet Connect
          </Button>
        </Center>
        <br />
      </Container>
    );
  }

  return (
<Box display={"block"} p={35} className="App" style={{backgroundImage: `url(${tldrtile})`}}>
      <LogoutButton />
      <Center>
        <img width={500} height={500} src={logo} alt="logo" />
      </Center>

      <Center>
        <Heading as="h2" size="3xl" p={10} className="ROAST">
          COMING SOON
        </Heading>
	  </Center>
	  <br />
	  <p className="Contract">24owls NFTs unlock</p>
	  <br />
	  <p className="Contract">one pound of NFT ROAST each</p>
	  <br />
	  <a className="Contract" href="https://polygonscan.com/token/0xcba09af827740795026ad842c96ba301d68be0c7"> https://polygonscan.com/token/0xcba09af827740795026ad842c96ba301d68be0c7 </a>
	  <br />
	  <br />
	  <Center>
        <img src={nftroast} alt="NFT ROAST" />
      </Center>
	  <br />
	  <Center>	
		<Heading as="h2" size="3xl" p={10} className="ROAST">
          from TLDR.COFFEE
        </Heading>
      </Center>
    </Box>
  );
}

export default App;
